@use 'fonts/wzb-text' as text;
@use 'colors/colors' as colors;

// stylelint-disable selector-max-specificity, selector-no-qualifying-type, selector-type-no-unknown, no-descending-specificity
.mat-tooltip {
  font-style: normal;
  font-weight: normal;
  font-size: 0.8125rem;
  line-height: 1.231;
  background-color: white;
  color: colors.$wzb-black !important;
  border-radius: 0.625rem !important;
  border: none;
  box-shadow: 0 0.625rem 1.25rem rgb(0 0 0 / 15%);
  padding: 0.875rem !important;
  white-space: pre-line;
}
