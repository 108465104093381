@use 'sass:color';
@use 'variables';
@use 'breakpoints' as breakpoints;
@use 'fonts/wzb-text' as text;
@use 'colors/colors' as colors;

.btn {
  &--outline-white {
    color: variables.$theme-primary;
    background-color: transparent;
    box-shadow: inset 0 0 0 0.0625rem variables.$theme-primary;
  }
}

.hover-on {
  .btn:hover {
    text-decoration: none;
    transform: scale(1.01);
    box-shadow: 0 0.625rem 1.25rem rgb(0 0 0 / 30%);
  }

  .btn--outline:hover {
    background-color: variables.$theme-primary;
    color: variables.$theme-secondary;
  }
}

.btn-square {
  padding: 0.625rem;
  border-radius: 0.625rem;
  border: 1px solid variables.$theme-secondary;
  line-height: 1;

  &:disabled, // use attribute `disabled` for buttons
  &--disabled {
    // use class `btn--disabled` with `tabindex="-1"` for links
    opacity: 0.3;
    pointer-events: none;
  }
}

.modes {
  display: flex;
  position: relative;
  z-index: 0;
  min-width: none;
  border-radius: 0.625rem;
  background-color: white;
  width: 100%;
  margin-bottom: 2.5rem;

  &__btn {
    font-style: normal;
    font-weight: 600;
    line-height: 1.375;
    box-sizing: border-box;
    position: relative;
    z-index: 2;
    padding: 1rem 1.25rem;
    width: 50%;
    transition:
      background-color 0.15s ease-out,
      transform 0.15s ease-out,
      box-shadow 0.15s ease-out,
      color 0.15s ease-out;

    &:not(.active) {
      &:hover {
        background-color: #e3f8ff;
        border-radius: 0.625rem;
      }
    }

    &__locked {
      position: absolute;
      top: 1.5625rem;
      right: 0.5625rem;
      font-size: 0.75rem;
      color: colors.$wzb-red;

      @include breakpoints.notHandset {
        top: 1.5625rem;
        right: 0.25rem;
      }
    }
  }

  &::before {
    content: '';
    z-index: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50%;
    background-color: variables.$theme-primary;
    transition: left 0.15s ease-out;
    border-radius: 0.625rem;
    box-shadow: 0 0.625rem 1.25rem rgb(0 0 0 / 15%);
  }

  &[position='left'] {
    &::before {
      left: 0;
    }
  }

  &[position='right'] {
    &::before {
      left: 50%;
    }
  }

  @include breakpoints.notHandset {
    margin-bottom: 1.25rem;
    width: auto;
    max-width: 23.125rem;
  }
}
