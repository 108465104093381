@use 'sass:color';
@use 'override/material';
@use 'variables';
@use 'button';
@use 'loader';
@use 'themes';
@use 'fonts/wzb-heading';
@use 'colors/colors' as colors;
@use 'gdpr';
@use 'breakpoints' as breakpoints;

// Angular material
@use '@angular/cdk' as cdk;

// Add normalize
@import '~normalize.css';
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

html {
  overflow: visible !important;
  // stylelint-disable-next-line font-family-name-quotes
  line-height: 1.625;
  text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  font-family: Montserrat;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding-right: 0 !important;
  overflow: visible scroll !important; // setting `overflow-y` has the unwanted effect of setting `overflow-x` to `auto`... // prevent jumps when switching between long and short pages
  color: colors.$wzb-black;
  background: linear-gradient(180.25deg, #d1e1f5cc 5.93%, #fffc 99.78%);
}

textarea {
  font-family: inherit;
  resize: vertical;
}

pre {
  overflow: auto;
}

label,
output {
  display: inline-block;
}

img,
svg {
  vertical-align: middle;
}

svg {
  display: block;
  border: 0;
  fill: none;

  &:not(:root) {
    overflow: hidden;
  }
}

a {
  color: inherit;
  text-decoration-skip-ink: auto;
  text-decoration: none;

  &:not([href]) {
    text-decoration: none !important;
  }
}

button,
input,
optgroup,
select,
textarea {
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}

input {
  @include cdk.text-field-autofill-color(color.adjust(variables.$theme-primary, $alpha: -0.8), colors.$wzb-black);

  // stylelint-disable-next-line selector-no-qualifying-type
  &[type='number'] {
    appearance: textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }
}

button,
summary {
  cursor: pointer;
}

strong {
  font-weight: 600;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

[hidden] {
  display: none !important;
}

[tabindex='-1']:focus {
  outline: 0 !important;
}

@media (prefers-reduced-motion) {
  * {
    transition: none !important;
    animation: none !important;
  }
}

.lock {
  overflow: hidden;
}

.highlighted {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  background-image: linear-gradient(
    hsl(0deg 0% 100% / 0%),
    hsl(0deg 0% 100% / 0%) 12%,
    colors.$wzb-green 0,
    colors.$wzb-green 70%,
    hsl(0deg 0% 100% / 0%) 0
  );
}

.btn-password {
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    fill: inherit;
    vertical-align: inherit;
  }
}

// stylelint-disable-next-line selector-type-no-unknown
ngx-skeleton-loader {
  display: flex;
}

.capitalize {
  text-transform: capitalize;
}

.wzb-position-picker {
  width: 2.375rem;
  height: 2.375rem;
  border-radius: 50%;
  background-color: colors.$wzb-black;
  color: colors.$wzb-light-blue;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wzb-position-picker-loader {
  box-sizing: border-box;
  display: inline-block;
  width: calc(#{2.375rem} - 0.25rem);
  height: calc(#{2.375rem} - 0.25rem);
  border-radius: 50%;
  border: 0.25rem solid silver;
  animation: wzb-position-picker-loader-anim 0.7s linear infinite;
  border-color: colors.$wzb-black;
  border-bottom-color: transparent;
  margin-bottom: -0.5rem;
}

@keyframes wzb-position-picker-loader-anim {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.header__menu__logo {
  .logo {
    margin-top: 0 !important;

    &__img {
      width: 5.9375rem;
    }

    @include breakpoints.notHandset {
      text-align: left !important;

      &__img {
        width: 6.8125rem;
      }
    }
  }
}

.header__trustpilot {
  position: absolute;
  right: -1.125rem;
}
