.mat-tab-nav-bar:not([initial]) {
  border-bottom: none;
  -ms-overflow-style: none;

  .mat-tab-link-container {
    overflow-x: auto;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
