@use 'variables';
@use 'breakpoints' as breakpoints;
@use 'colors/colors' as wzbVars;

[class^='gdpr_overlay'] {
  transform: none !important;

  & > * {
    transform: none !important;
  }
}

[class^='gdpr_banner'] {
  margin-left: auto;
  margin-right: auto;
}

[class^='gdpr_banner__cookie_icon'] {
  font-size: 1rem;
}

[class^='gdpr_banner__title'] {
  font-size: 1rem !important;
  font-weight: 600 !important;
}

[class^='gdpr_banner__text'] {
  font-weight: 300 !important;
}

[class*='activated'] [class^='gdpr_switch__button'] {
  background-color: variables.$theme-primary !important;
}
[class*='activated'] [class^='gdpr_switch__label'] {
  color: variables.$theme-primary !important;
}

[class^='gdpr_banner__accept'] {
  @include breakpoints.handset {
    &:hover {
      background: variables.$theme-primary !important;
      color: white !important;
    }
  }
}

[class^='gdpr_dialogbox__title'] {
  text-align: center;
  color: variables.$theme-primary !important;
}

[class^='gdpr_dialogbox__cross'] {
  svg {
    fill: wzbVars.$wzb-black;
  }
}

.theme-gdpr-permii,
.theme-gdpr-permii-app,
.theme-gdpr-fibii,
.theme-gdpr-money,
.theme-gdpr-money_nexity,
.theme-gdpr-money-nexity {
  [class*='activated'] [class^='gdpr_switch__button'] {
    background-color: wzbVars.$wzb-light-blue !important;
  }

  [class*='activated'] [class^='gdpr_switch__label'] {
    color: wzbVars.$wzb-light-blue !important;
  }

  [class^='gdpr_banner__accept'] {
    background-color: wzbVars.$wzb-light-blue !important;
    color: wzbVars.$wzb-black !important;

    &:hover {
      background-color: wzbVars.$grey-4 !important;
    }
  }

  [class^='gdpr_dialogbox__title'] {
    color: wzbVars.$wzb-light-blue !important;
  }
}
