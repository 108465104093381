// stylelint-disable selector-max-specificity, selector-no-qualifying-type, selector-type-no-unknown, no-descending-specificity
.mat-progress-bar {
  .mat-progress-fill {
    border-radius: 0.125rem;
    overflow: hidden;
  }

  &--only-progress {
    .mat-progress-bar-buffer {
      display: none;
    }

    .mat-progress-bar-background {
      display: none;
    }
  }

  &--fixed-top {
    position: fixed !important;
    top: 0;
    z-index: 200;
  }
}
