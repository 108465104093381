@use 'material/mat-dialog';
@use 'material/mat-progress-bar';
@use 'material/mat-tooltip';
@use 'material/mat-tab';
@use 'material/mat-input';
@use 'material/mat-radio-button';

.mat-mdc-form-field-error {
  margin-top: 0 !important;
  text-align: end !important;
}
