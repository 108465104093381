// stylelint-disable selector-max-specificity, selector-no-qualifying-type, selector-type-no-unknown, no-descending-specificity
@use 'breakpoints' as breakpoints;
@use 'colors/colors' as colors;

.mat-mdc-radio-button {
  label {
    font-size: 0.875rem;
    padding: 0 !important;
  }

  .mdc-radio {
    padding: 0.375rem !important;

    &__outer-circle {
      border: 2px solid colors.$grey-4 !important;
      background-color: transparent !important;
    }
    &__inner-circle {
      background-color: transparent !important;
    }

    &__background::before {
      background-color: transparent !important;
    }
  }

  .mat-radio-ripple .mat-ripple-element {
    opacity: 0 !important;
    background-color: colors.$wzb-light-blue !important;
  }

  &:hover,
  &:focus-within {
    .mdc-radio {
      &__outer-circle {
        border-color: colors.$wzb-light-blue !important;
        background-color: transparent !important;
      }
    }
  }

  @include breakpoints.notHandset {
    label {
      font-size: 1rem;
    }
  }
}

.mat-mdc-radio-checked,
.mat-mdc-radio-checked.mat-mdc-radio-button:hover,
.mat-mdc-radio-checked.mat-mdc-radio-button:focus-within {
  .mdc-radio {
    &__outer-circle {
      border-color: colors.$wzb-black !important;
      background-color: colors.$wzb-black !important;
    }
    &__inner-circle {
      background-color: white !important;
      transform: scale(0.3) !important;
    }
  }
}

.mat-mdc-radio-group.ng-invalid.ng-touched {
  .mat-mdc-radio-button label {
    color: colors.$wzb-red !important;
  }

  .mdc-radio__outer-circle {
    border: 1.5px solid colors.$wzb-red !important;
  }

  &:focus-within {
    .mdc-radio {
      &__outer-circle {
        border: 2px solid colors.$wzb-red !important;
      }
    }
  }
}
