@use 'theming' as theming;
@use 'colors/colors' as colors;

$contrast: (
  50: black,
  100: black,
  200: black,
  300: black,
  400: black,
  500: black,
  600: black,
  700: black,
  800: white,
  900: white,
  a100: black,
  a200: black,
  a400: black,
  a700: black,
);

// Define the palette using : https://mcg.mbitson.com
$md-purple-dark: (
  50: #f0f0ff,
  100: #dbdaff,
  200: #c3c1ff,
  300: #aaa8ff,
  400: #9896ff,
  500: #8683ff,
  600: #7e7bff,
  700: #7370ff,
  800: #6966ff,
  900: #5653ff,
  A100: white,
  A200: white,
  A400: #f8f8ff,
  A700: #dfdfff,
  contrast: $contrast,
);
$md-blue: (
  50: #e9f9ff,
  100: #c7f0ff,
  200: #a2e7ff,
  300: #7dddff,
  400: #61d5ff,
  500: #45ceff,
  600: #3ec9ff,
  700: #36c2ff,
  800: #2ebcff,
  900: #1fb0ff,
  A100: white,
  A200: white,
  A400: #ceedff,
  A700: #b5e3ff,
  contrast: $contrast,
);
$md-yellow: (
  50: #fefde4,
  100: #fdfabc,
  200: #fcf690,
  300: #fbf263,
  400: #faf041,
  500: #f9ed20,
  600: #f8eb1c,
  700: #f7e818,
  800: #f6e513,
  900: #f5e00b,
  A100: white,
  A200: #fffdeb,
  A400: #fff8b8,
  A700: #fff69f,
  contrast: $contrast,
);
$md-green: (
  50: #e8fef8,
  100: #c5fdee,
  200: #9efce3,
  300: #77fbd7,
  400: #5afacf,
  500: #3df9c6,
  600: #37f8c0,
  700: #2ff7b9,
  800: #27f6b1,
  900: #1af5a4,
  A100: white,
  A200: #f4fffb,
  A400: #c1ffe7,
  A700: #a7ffdd,
  contrast: (
    50: black,
    100: black,
    200: black,
    300: black,
    400: black,
    500: black,
    600: black,
    700: black,
    800: black,
    900: black,
    A100: black,
    A200: black,
    A400: black,
    A700: black,
  ),
);
$md-wzb-red: (
  50: #ffebec,
  100: #ffcecf,
  200: #ffaeb0,
  300: #ff8d90,
  400: #ff7478,
  500: #ff5c60,
  600: #ff5458,
  700: #ff4a4e,
  800: #ff4144,
  900: #ff3033,
  A100: white,
  A200: white,
  A400: #ffdbdc,
  A700: #ffc2c3,
  contrast: $contrast,
);

@include theming.wzbTheming($md-purple-dark, $md-green, $md-wzb-red);

.theme-permii,
.theme-permii-app,
.theme-fibii,
.theme-money-intl,
.theme-fibii_nexity,
.theme-fibii-nexity {
  @include theming.wzbTheming($md-blue, $md-green, $md-wzb-red);

  --primary: #{colors.$wzb-light-blue};

  &__link,
  a {
    color: colors.$wzb-light-blue !important;
  }

  &__btn-link:hover {
    background-color: colors.$wzb-light-blue !important;
    border-color: colors.$wzb-light-blue !important;
  }
}
