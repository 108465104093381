@use 'colors/colors' as colors;

.social-connect-loading {
  position: relative;
  border-top: 0.1875rem solid rgb(255 255 255 / 20%);
  border-right: 0.1875rem solid rgb(255 255 255 / 20%);
  border-bottom: 0.1875rem solid rgb(255 255 255 / 20%);
  border-left: 0.1875rem solid colors.$wzb-purple;
  transform: translateZ(0);
  animation: load 1.1s infinite linear;
}

.social-connect-loading,
.social-connect-loading::after {
  border-radius: 50%;
  width: 0.8rem;
  height: 0.8rem;
}

@keyframes load {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
